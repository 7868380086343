<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="确认逝者生平事迹"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 13 }"
        @submit="handleSubmit"
      >
        <a-form-item label="是否需要司仪稿">
          <a-checkbox @change="changeNeedEmceeDraft" :checked="isNeedEmceeDraft">
          </a-checkbox>
        </a-form-item>
        <span v-if="isNeedEmceeDraft" style="margin-left:50px;">请确认撰写司仪稿负责人</span>
        <a-form-item label="负责人" v-if="isNeedEmceeDraft">
          <a-select
            v-decorator="['user_id', {
              initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,
              rules: [
                { required: true, message: '负责人' },
              ]
            }]"
            placeholder="请选择负责人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="isNeedEmceeDraft" label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['phone_number', {
              initialValue: defaultUser.phone_number,
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { confirmedLifeInfo } from '@/api/order'
import { findTenantTaskUserOptions, findUser } from '@/api/user'

export default {
  name: 'ConfirmedLifeInfo',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'confirmed_life_info' }),
      submitting: false,
      userOptions: [],
      defaultUser: {},
      loadingUserOptions: false,
      data: {},
      isNeedEmceeDraft: false // 是否需要司仪稿
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions()
  },
  methods: {
    handlePhoneNumberChange(id) {
      this.form.setFieldsValue({ phone_number: undefined })
      if (!id) {
        return
      }
      findUser(id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ phone_number: res.data.phone_number })
        }
      })
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantTaskUserOptions({ task_type: 'writing_emcee_draft', order_id: this.serviceOrderId, order_type: 'service' }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data.option_list
          this.defaultUser = res.data.default_user
        }
        this.loadingUserOptions = false
      })
    },
    changeNeedEmceeDraft(e) {
      this.isNeedEmceeDraft = e.target.checked
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          confirmedLifeInfo(this.serviceOrderId, Object.assign({ is_need_emcee_draft: this.isNeedEmceeDraft }, values)).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
