var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"确认逝者生平事迹"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 13 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"是否需要司仪稿"}},[_c('a-checkbox',{attrs:{"checked":_vm.isNeedEmceeDraft},on:{"change":_vm.changeNeedEmceeDraft}})],1),(_vm.isNeedEmceeDraft)?_c('span',{staticStyle:{"margin-left":"50px"}},[_vm._v("请确认撰写司仪稿负责人")]):_vm._e(),(_vm.isNeedEmceeDraft)?_c('a-form-item',{attrs:{"label":"负责人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id', {
            initialValue: _vm.defaultUser.id > 0 ? _vm.defaultUser.id : undefined,
            rules: [
              { required: true, message: '负责人' } ]
          }]),expression:"['user_id', {\n            initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,\n            rules: [\n              { required: true, message: '负责人' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择负责人"},on:{"load":_vm.loadingUserOptions,"change":_vm.handlePhoneNumberChange}},_vm._l((_vm.userOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1):_vm._e(),(_vm.isNeedEmceeDraft)?_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            initialValue: _vm.defaultUser.phone_number,
            normalize: this.$lodash.trim
          }]),expression:"['phone_number', {\n            initialValue: defaultUser.phone_number,\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }